import * as Yup from 'yup';
import {FormikFieldTypes, FormikFieldConfig, IRestaurantOutput} from 'reservation-common-web';
import {
    getCountOptions,
    getReservationStartTimeOptions,
    getRoomOptions,
    getMaxDate,
    getMinDate,
    getExcludedDateOptions,
} from '../../../../utils/formUtils';

const reservationDetailsFormConfig = (
    t: (key: string) => string,
    restaurant: IRestaurantOutput | null,
    restaurantPlanButton: React.ReactNode
): FormikFieldConfig[] => {
    return [
        {
            name: 'date',
            label: t('reservationForm.reservationDetails.form.labels.date'),
            type: FormikFieldTypes.DATE,
            validation: Yup.date().required(t('validation.required')),
            className: '',
            dateFormat: 'yyyy-MM-dd',
            isRequired: true,
            excludeDates: getExcludedDateOptions(restaurant),
            minDate: getMinDate(),
            maxDate: getMaxDate(),
            disabledKeyboardNavigation: false,
        },
        {
            name: 'count',
            label: t('reservationForm.reservationDetails.form.labels.count'),
            type: FormikFieldTypes.SELECT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
            isRequired: true,
            options: getCountOptions(restaurant),
            isSearchable: false,
        },
        {
            name: 'time',
            label: t('reservationForm.reservationDetails.form.labels.time'),
            type: FormikFieldTypes.SELECT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
            isRequired: true,
            options: getReservationStartTimeOptions(restaurant),
            isSearchable: false,
        },
        {
            name: 'roomId',
            label: t('reservationForm.reservationDetails.form.labels.roomId'),
            type: FormikFieldTypes.SELECT,
            validation: Yup.string().required(t('validation.required')),
            className: 'room-select',
            options: getRoomOptions(restaurant),
            isSearchable: false,
        },
        {
            type: FormikFieldTypes.CUSTOM,
            label: '',
            validation: Yup.string(),
            name: 'restaurant-plan',
            customElement: restaurantPlanButton,
            className: '',
        },
    ];
};

export default reservationDetailsFormConfig;
