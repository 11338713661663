import React from 'react';
import {Translation, formatDateToString} from 'reservation-common-web';
import {useSelector} from 'react-redux';
import {IReservationDetails} from '../../../../../store/reducers/reservationSlice';
import {reservationDetailsSelector} from '../../../../../store/selectors/reservationSelector';

const ReservationSummary: React.FC = () => {
    const reservationDetails: IReservationDetails = useSelector(reservationDetailsSelector);

    return (
        <div className="reservation-summary">
            <div className="title-container">
                <h2 className="title">
                    <Translation text={'reservationForm.purchaserDetails.reservationSummary.title'} />
                </h2>
            </div>
            <div className="reservation-summary-details">
                <p className="reservation-summary-time">
                    <span>{reservationDetails.date && formatDateToString(new Date(reservationDetails.date))}</span>
                    <span>{reservationDetails.time}</span>
                </p>
                <p className="reservation-summary-count">
                    <span>
                        <Translation text={'reservationForm.purchaserDetails.reservationSummary.count'} />
                    </span>
                    <span>{reservationDetails.count}</span>
                </p>
            </div>
        </div>
    );
};

export default ReservationSummary;
