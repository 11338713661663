import React from 'react';
import ReservationWizard from './ReservationWizard';
import ReservationHeader from './ReservationFormHeader';

interface IReservationCardProps {}

const ReservationCard: React.FC<IReservationCardProps> = () => {
    return (
        <div className="card">
            <div className="card-header">
                <ReservationHeader />
            </div>
            <div className="card-body">
                <ReservationWizard />
            </div>
        </div>
    );
};

export default ReservationCard;
