import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FormGenerator, Translation} from 'reservation-common-web';
import {purchaserDetailsSelector, isPurchaserDetailsValidSelector} from '../../../../store/selectors/reservationSelector';
import purchaserDetailsFormControl from './purchaserDetailsFormControl';
import ReservationSummary from './ReservationSummary';
import {IPurchaserDetails, ReservationFormSteps, setCurrentStep, setPurchaserDetails} from '../../../../store/reducers/reservationSlice';
import ReservationFee from './ReservationFee';
import {btnPrimaryStyles} from '../../../../utils/stylesUtil';
import {restaurantColorSelector} from '../../../../store/selectors/restaurantSelector';

const PurchaserDetailsStep: React.FC = () => {
    const {t} = useTranslation(),
        dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const purchaserDetails: IPurchaserDetails = useSelector(purchaserDetailsSelector),
        isPurchaserDetailsValid: boolean = useSelector(isPurchaserDetailsValidSelector),
        restaurantColor: string | null = useSelector(restaurantColorSelector);

    const valuesFromState = useSelector(purchaserDetailsSelector);

    const showPrivacyPolicyStep = () => {
        dispatch(setCurrentStep(ReservationFormSteps.PRIVACY_POLICY));
    };

    const initialValues: IPurchaserDetails = {
        guestName: purchaserDetails.guestName || '',
        phone: purchaserDetails.phone || '',
        email: purchaserDetails.email || '',
        message: purchaserDetails.message || '',
        privacyPolicy: purchaserDetails.privacyPolicy || false,
    };

    const handleSubmit = () => {
        dispatch(setCurrentStep(ReservationFormSteps.STRIPE_PAYMENT));
    };
    return (
        <div className="step purchaser-details-step">
            <ReservationSummary />
            <FormGenerator
                config={{
                    fields: purchaserDetailsFormControl(t, showPrivacyPolicyStep),
                    initialValues: initialValues,
                    onSubmit: (e) => e.preventDefault(),
                    onChange: (values) => {
                        if (values !== valuesFromState) dispatch(setPurchaserDetails(values));
                    },
                    formId: 'change-purchaser-details-form',
                    formClassName: '',
                }}
            />
            <ReservationFee />
            <div className="form-footer">
                <button
                    className="btn-primary"
                    style={btnPrimaryStyles(restaurantColor)}
                    disabled={!isPurchaserDetailsValid}
                    onClick={handleSubmit}>
                    <Translation text={'reservationForm.buttons.pay'} />
                </button>
                <button className="btn-secondary" onClick={() => dispatch(setCurrentStep(ReservationFormSteps.RESERVATION_DETAILS))}>
                    <Translation text={'reservationForm.buttons.previous'} />
                </button>
            </div>
        </div>
    );
};

export default PurchaserDetailsStep;
