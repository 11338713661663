import React from 'react';
import {Translation} from 'reservation-common-web';
import {ReservationFormSteps, setCurrentStep} from '../../../../../store/reducers/reservationSlice';
import {useDispatch, useSelector} from 'react-redux';
import {restaurantColorSelector} from '../../../../../store/selectors/restaurantSelector';
import {btnPrimaryOutlineStyles} from '../../../../../utils/stylesUtil';

const RestaurantPlanButton: React.FC = () => {
    const dispatch = useDispatch();
    const restaurantColor: string | null = useSelector(restaurantColorSelector);

    return (
        <div className="form-control show-plan-btn">
            <button
                type="button"
                className="btn-primary-outline"
                style={btnPrimaryOutlineStyles(restaurantColor)}
                onClick={() => dispatch(setCurrentStep(ReservationFormSteps.RESTAURANT_PLAN))}>
                <Translation text={'reservationForm.buttons.showPlan'} />
            </button>
        </div>
    );
};

export default RestaurantPlanButton;
