import React from 'react';
import {Route} from 'react-router';
import ViewHost from '../components/ViewHost';
import NotFound from '../components/NotFound';

const routes = [
    <Route path="/restaurant/:slug" key="panel" element={<ViewHost />} />,
    <Route path="*" key="not-found" element={<NotFound />} />,
];
export default routes;
