const isValidColor = (color: string | null): boolean => {
    if (!color) return false;

    const regExp1 = /[0-9A-Fa-f]{6}/g;
    const regExp2 = /[0-9A-Fa-f]{3}/g;
    return regExp1.test(color) || regExp2.test(color);
};

const getColor = (color: string | null): any => {
    return {color: `#${color}`};
};

const getBorderColor = (color: string | null): any => {
    return {borderColor: `#${color}`};
};

const getBackgroundColor = (color: string | null): any => {
    return {backgroundColor: `#${color}`};
};

export const textColorStyles = (color: string | null) => {
    if (!isValidColor(color)) return {};

    const btnColor = getColor(color);

    return {...btnColor};
};

export const btnPrimaryStyles = (color: string | null) => {
    if (!isValidColor(color)) return {};

    const btnBackgroundColor = getBackgroundColor(color);

    return {...btnBackgroundColor};
};

export const btnPrimaryOutlineStyles = (color: string | null) => {
    if (!isValidColor(color)) return {};

    const btnColor = getColor(color);
    const btnBorderColor = getBorderColor(color);

    return {...btnColor, ...btnBorderColor};
};
