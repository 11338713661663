import {IRestaurantOutput, IRoomOutput, IOpenHoursOutput} from 'reservation-common-web';

export interface ISelectOptions {
    // toDo PPPP
    label: string;
    value: string | number;
}

export const getRoomOptions = (restaurant: IRestaurantOutput | null): ISelectOptions[] => {
    if (!restaurant || !restaurant.rooms.length) return [];

    const roomOptions: ISelectOptions[] = [];

    restaurant.rooms.forEach((room: IRoomOutput) => {
        roomOptions.push({
            label: room.name || '',
            value: room.id,
        });
    });

    return roomOptions;
};

export const getReservationStartTimeOptions = (restaurant: IRestaurantOutput | null): ISelectOptions[] => {
    if (!restaurant || !restaurant.rooms.length) return [];

    const convertHour = (hStr: string) => {
        if (hStr.length < 5) {
            console.log('HH:mm getReservationStartTimeOptions');
            return 19;
        }

        const HH: number = parseInt(hStr.substring(0, 2));
        const mm: number = parseInt(hStr.substring(3, 5)) / 60;

        return HH + mm;
    };

    const startStr = restaurant.rooms[0].hourStart,
        endStr = restaurant.rooms[0].hourEnd,
        step = restaurant.rooms[0].step;

    const startNum = convertHour(startStr);
    const endNum = convertHour(endStr);
    const stepNum = step / 60;

    const timeOptions: ISelectOptions[] = [];

    let t = startNum;
    while (t <= endNum) {
        const hour = Math.floor(t);
        const dec = t - hour;
        const min = (dec * 60).toLocaleString('pl-PL', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        });

        const HHmm = `${hour}:${min}`;
        timeOptions.push({
            label: HHmm,
            value: HHmm,
        });

        t += stepNum;
    }
    return timeOptions;
};

export const getCountOptions = (restaurant: IRestaurantOutput | null): ISelectOptions[] => {
    if (!restaurant || !restaurant.rooms.length) return [];

    const maxCount = restaurant.rooms[0].maxCountPerReservation;
    const countOptions: ISelectOptions[] = [];

    let count = 1;
    while (count <= maxCount) {
        countOptions.push({
            label: `${count}`,
            value: count,
        });

        count++;
    }

    return countOptions;
};

const DAY_DURATION = 60 * 60 * 24 * 1000;
const DEFAULT_BOOKING_DAY_RANGE = 31;
export const getMinDate = () => {
    return new Date(new Date().getTime() + DAY_DURATION);
};

export const getMaxDate = () => {
    return new Date(new Date().getTime() + DAY_DURATION * DEFAULT_BOOKING_DAY_RANGE);
};

export const getExcludedDateOptions = (restaurant: IRestaurantOutput | null): Date[] => {
    if (!restaurant || !restaurant.configuration?.openHours) return [];

    const excludedDates: Date[] = [];

    for (let i = 1; i <= DEFAULT_BOOKING_DAY_RANGE; i++) {
        const day = new Date(new Date().getTime() + i * DAY_DURATION);
        const dayOfWeek = day.getDay();
        const dayKey: keyof IOpenHoursOutput = `_${dayOfWeek}` as keyof IOpenHoursOutput;
        if (!(dayKey in restaurant.configuration.openHours)) excludedDates.push(day);
    }
    return excludedDates;
};
