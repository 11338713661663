import React from 'react';
import {Translation, IRestaurantOutput} from 'reservation-common-web';
import {useDispatch, useSelector} from 'react-redux';
import {ReservationFormSteps, setCurrentStep} from '../../../../store/reducers/reservationSlice';
import {restaurantColorSelector, restaurantSelector} from '../../../../store/selectors/restaurantSelector';
import {btnPrimaryOutlineStyles} from '../../../../utils/stylesUtil';

const PrivacyPolicyStepStep: React.FC = () => {
    const dispatch = useDispatch();
    const restaurant: IRestaurantOutput | null = useSelector(restaurantSelector),
        restaurantColor: string | null = useSelector(restaurantColorSelector);

    const renderPrivacyPolicyStep = () => {
        if (!restaurant?.configuration?.privacyPolicy) return;

        return <div className="privacy-policy" dangerouslySetInnerHTML={{__html: restaurant?.configuration?.privacyPolicy}} />;
    };

    return (
        <div className="step privacy-policy-step">
            <div className="title-container">
                <h2 className="title">
                    <Translation text={'reservationForm.privacyPolicy.restaurantTitle'} />
                </h2>
            </div>
            <div className="privacy-policy-container">{renderPrivacyPolicyStep()}</div>
            <div className="title-container">
                <h2 className="title">
                    <Translation text={'reservationForm.privacyPolicy.providerTitle'} />
                </h2>
            </div>
            <div className="privacy-policy-container">{renderPrivacyPolicyStep()}</div>
            <div className="form-footer">
                <button
                    className="btn-primary-outline"
                    style={btnPrimaryOutlineStyles(restaurantColor)}
                    onClick={() => dispatch(setCurrentStep(ReservationFormSteps.PURCHASER_DETAILS))}>
                    <span className="btn-icon" />
                    <Translation text={'reservationForm.buttons.back'} />
                </button>
            </div>
        </div>
    );
};

export default PrivacyPolicyStepStep;
