import React from 'react';
import {Translation, IRestaurantOutput} from 'reservation-common-web';
import {useSelector} from 'react-redux';
import {restaurantSelector} from '../../../store/selectors/restaurantSelector';

interface IReservationHeaderProps {}

const DEFAULT_AVATAR = 'https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image-300x300.png'; //toDo PPPP

const ReservationHeader: React.FC<IReservationHeaderProps> = () => {
    const restaurant: IRestaurantOutput | null = useSelector(restaurantSelector);
    const renderLogo = () => {
        const smallThumb = restaurant?.configuration?.avatar?.fileUrls?.smallThumb,
            imgSrc = smallThumb ? smallThumb : DEFAULT_AVATAR;

        return (
            <div className="logo-container">
                <div className="logo" style={{backgroundImage: `url(${imgSrc})`}} />
            </div>
        );
    };
    return (
        <>
            {renderLogo()}

            <div className="restaurant-details">
                <h1 className="name">
                    <Translation text={'reservationForm.title'} />
                </h1>
                <p className="details">{restaurant?.name}</p>
                <p className="details">{restaurant?.address}</p>
            </div>
        </>
    );
};

export default ReservationHeader;
