import React from 'react';
import {useSelector} from 'react-redux';
import {ReservationFormSteps} from '../../../store/reducers/reservationSlice';
import {currentStepSelector} from '../../../store/selectors/reservationSelector';
import PurchaserDetailsStep from './PurchaserDetailsStep';
import ReservationDetailsStep from './ReservationDetailsStep';
import RestaurantPlanStep from './RestaurantPlanStep';
import PrivacyPolicyStep from './PrivacyPolicyStep';
import StripePaymentStep from './StripePaymentStep';
import ThankYouStep from './ThankYouStep';

interface IReservationWizardProps {}

const ReservationWizard: React.FC<IReservationWizardProps> = () => {
    const currentStep = useSelector(currentStepSelector);

    const renderStep = () => {
        switch (currentStep) {
            case ReservationFormSteps.RESERVATION_DETAILS:
                return <ReservationDetailsStep />;
            case ReservationFormSteps.RESTAURANT_PLAN:
                return <RestaurantPlanStep />;
            case ReservationFormSteps.PURCHASER_DETAILS:
                return <PurchaserDetailsStep />;
            case ReservationFormSteps.PRIVACY_POLICY:
                return <PrivacyPolicyStep />;
            case ReservationFormSteps.STRIPE_PAYMENT:
                return <StripePaymentStep />;
            case ReservationFormSteps.THANK_YOU_PAGE:
                return <ThankYouStep />;
            default:
                return <div>{'error'}</div>;
        }
    };

    return <div className="wizard-container">{renderStep()}</div>;
};

export default ReservationWizard;
