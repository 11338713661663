import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FormGenerator, Translation, IRestaurantOutput} from 'reservation-common-web';
import {
    IReservationDetails,
    ReservationFormSteps,
    setCurrentStep,
    setReservationDetails,
} from '../../../../store/reducers/reservationSlice';
import {isReservationDetailsValidSelector, reservationDetailsSelector} from '../../../../store/selectors/reservationSelector';
import reservationDetailsFormConfig from './reservationDetailsFormConfig';
import {restaurantColorSelector, restaurantSelector} from '../../../../store/selectors/restaurantSelector';
import RestaurantPlanButton from './RestaurantPlanButton';
import {btnPrimaryStyles} from '../../../../utils/stylesUtil';

const ReservationDetailsStep: React.FC = () => {
    const {t} = useTranslation(),
        dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const restaurant: IRestaurantOutput | null = useSelector(restaurantSelector),
        restaurantColor: string | null = useSelector(restaurantColorSelector),
        reservationDetails: IReservationDetails = useSelector(reservationDetailsSelector),
        isReservationDetailsValid: boolean = useSelector(isReservationDetailsValidSelector);

    const initialValues = {
        date: reservationDetails.date || '',
        count: reservationDetails.count || '',
        time: reservationDetails.time || '',
        roomId: reservationDetails.roomId || '',
    };

    const renderInitialDescription = () => {
        if (!restaurant?.configuration?.initialDescription) return;

        return <div className="initial-description" dangerouslySetInnerHTML={{__html: restaurant.configuration.initialDescription}} />;
    };

    const fields = reservationDetailsFormConfig(t, restaurant, <RestaurantPlanButton />);

    return (
        <div className="step reservation-details-step">
            {renderInitialDescription()}

            <FormGenerator
                config={{
                    fields: fields,
                    initialValues: initialValues,
                    onSubmit: (e) => e.preventDefault(),
                    onChange: (values) => {
                        if (values !== reservationDetails) {
                            dispatch(setReservationDetails(values));
                        }
                    },
                    formId: 'change-reservation-details-form',
                }}
            />
            <div className="form-footer">
                <button
                    className="btn-primary"
                    style={btnPrimaryStyles(restaurantColor)}
                    onClick={() => dispatch(setCurrentStep(ReservationFormSteps.PURCHASER_DETAILS))}
                    disabled={!isReservationDetailsValid}>
                    <Translation text={'reservationForm.buttons.next'} />
                </button>
            </div>
        </div>
    );
};

export default ReservationDetailsStep;
