import {combineEpics} from 'redux-observable';
import {getRestaurant, setRestaurant, setRestaurantError} from '../reducers/restaurantSlice';
import {createFetchDetailsEpic, DetailsSuccessActionsFunction, getAnonymousRestaurantAPI, IRestaurantOutput} from 'reservation-common-web';

const getRestaurantSuccessActions: DetailsSuccessActionsFunction<IRestaurantOutput> = (restaurant: IRestaurantOutput) => {
    return [setRestaurant(restaurant)];
};

const errorActions = (): any[] => {
    return [setRestaurantError('error')]; // toDo PPPP
};

const getRestaurantEpic = createFetchDetailsEpic<IRestaurantOutput>(
    getAnonymousRestaurantAPI,
    getRestaurantSuccessActions,
    errorActions,
    getRestaurant().type
);

const restaurantEpic = combineEpics(getRestaurantEpic);

export default restaurantEpic;
