import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IReservationState} from '../reducers/reservationSlice';
import * as yup from 'yup';

export const selectReservationSlice = (state: RootState) => state.reservation;

export const reservationDetailsSelector = createSelector([selectReservationSlice], (state: IReservationState) => state.reservationDetails);

export const isReservationDetailsValidSelector = createSelector(
    [selectReservationSlice],
    (state: IReservationState) =>
        !!(
            state.reservationDetails.date &&
            state.reservationDetails.time &&
            state.reservationDetails.count &&
            state.reservationDetails.roomId
        )
);

export const purchaserDetailsSelector = createSelector([selectReservationSlice], (state: IReservationState) => state.purchaserDetails);

export const isPurchaserDetailsValidSelector = createSelector([selectReservationSlice], (state: IReservationState) => {
    const emailSchema = yup.string().email();

    return !!(
        state.purchaserDetails.guestName &&
        state.purchaserDetails.phone &&
        state.purchaserDetails.privacyPolicy &&
        emailSchema.isValidSync(state.purchaserDetails.email)
    );
});

export const clientSecretSelector = createSelector([selectReservationSlice], (state: IReservationState) => state.clientSecret);

export const reservationPriceSelector = createSelector([selectReservationSlice], (state: IReservationState) => state.reservationPrice);

export const currentStepSelector = createSelector([selectReservationSlice], (state: IReservationState) => state.currentStep);

export const isReservationPriceLoadingSelector = createSelector(
    [selectReservationSlice],
    (state: IReservationState) => state.isReservationPriceLoading
);

export const isReservationSliceLoadingSelector = createSelector([selectReservationSlice], (state: IReservationState) => state.isLoading);

export const reservationSliceErrorSelector = createSelector([selectReservationSlice], (state: IReservationState) => state.error);

export const shouldReestimateReservationPriceSelector = createSelector(
    [selectReservationSlice],
    (state: IReservationState) => state.shouldReestimateReservationPrice
);

export const isNavigationOutsideViewSelector = createSelector(
    [selectReservationSlice],
    (state: IReservationState) => state.isNavigationOutsideView
);
