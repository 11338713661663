import React from 'react';
import {IRestaurantOutput} from 'reservation-common-web';
import {useSelector} from 'react-redux';
import {IReservationDetails} from '../../../../../store/reducers/reservationSlice';
import {reservationDetailsSelector} from '../../../../../store/selectors/reservationSelector';
import {Trans} from 'react-i18next';
import {restaurantColorSelector, restaurantSelector} from '../../../../../store/selectors/restaurantSelector';
import {textColorStyles} from '../../../../../utils/stylesUtil';

const ReservationFee: React.FC = () => {
    const restaurant: IRestaurantOutput | null = useSelector(restaurantSelector),
        reservationDetails: IReservationDetails = useSelector(reservationDetailsSelector),
        restaurantColor: string | null = useSelector(restaurantColorSelector);

    console.log(restaurant);

    const renderReservationFee = () => {
        // if (restaurant && restaurant.rooms[0].prepayRequired) {
        //toDO PPPP && restaurant.rooms[0].prepayPerPerson i 20zł
        // const perPersonAmount = parseInt(restaurant.rooms[0].prepayPerPerson.amount);
        //
        if (!reservationDetails.count) return <div />;

        const amount: number = 20 * reservationDetails.count;

        return (
            <div className="reservation-fee">
                <p>
                    <Trans
                        i18nKey="reservationForm.reservationDetails.reservationFee"
                        values={{amount: amount, currency: 'zł'}}
                        components={[<span key={'reservation-fee'} style={textColorStyles(restaurantColor)} />]}
                    />
                </p>
            </div>
        );
        // }

        // return <div />;
    };

    return renderReservationFee();
};

export default ReservationFee;
