import {FormikFieldConfig, FormikFieldTypes, FormikCustomValidators} from 'reservation-common-web';
import * as Yup from 'yup';

const purchaserDetailsFormControl = (t: (key: string) => string, showPrivacyPolicyStep: () => void): FormikFieldConfig[] => {
    return [
        {
            name: 'guestName',
            label: t('reservationForm.purchaserDetails.form.labels.guestName'),
            type: FormikFieldTypes.TEXT,
            validation: Yup.string().required(t('validation.required')),
            isRequired: true,
            className: '',
        },
        {
            name: 'phone',
            label: t('reservationForm.purchaserDetails.form.labels.phone'),
            type: FormikFieldTypes.TEXT,
            validation: Yup.string().required(t('validation.required')).matches(FormikCustomValidators.PHONE, t('validation.invalidPhone')),
            isRequired: true,
            className: '',
        },
        {
            name: 'email',
            label: t('reservationForm.purchaserDetails.form.labels.email'),
            type: FormikFieldTypes.EMAIL,
            validation: Yup.string().required(t('validation.required')).matches(FormikCustomValidators.EMAIL, t('validation.invalidEmail')),
            isRequired: true,
            className: '',
        },
        {
            name: 'message',
            label: t('reservationForm.purchaserDetails.form.labels.message'),
            type: FormikFieldTypes.TEXTAREA,
            validation: Yup.string().min(0),
            className: '',
        },
        {
            name: 'privacyPolicy',
            label: t(`reservationForm.purchaserDetails.form.labels.privacyPolicy`),
            type: FormikFieldTypes.SWITCH,
            isLabelWithLink: true,
            validation: Yup.string().required(t('validation.required')),
            className: '',
            isRequired: true,
            linkAction: showPrivacyPolicyStep,
        },
    ];
};

export default purchaserDetailsFormControl;
