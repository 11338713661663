import {PayloadAction} from '@reduxjs/toolkit';
import {IMoneyInput, IReservationCreateInput, createBaseReducerSlice} from 'reservation-common-web';

export const enum ReservationFormSteps {
    RESERVATION_DETAILS = 'reservationDetails',
    RESTAURANT_PLAN = 'restaurantPlan',
    PURCHASER_DETAILS = 'purchaserDetails',
    PRIVACY_POLICY = 'privacyPolicy',
    STRIPE_PAYMENT = 'stripePayment',
    THANK_YOU_PAGE = 'thankYouPage',
}

export interface IReservationDetails {
    date: string | null;
    count: number | null;
    time: string | null;
    roomId: string | null;
}

export interface IPurchaserDetails {
    guestName: string | null;
    phone: string | null;
    email: string | null;
    message: string | null;
    privacyPolicy: boolean | null;
}

export interface IReservationState {
    reservationDetails: IReservationDetails;
    purchaserDetails: IPurchaserDetails;
    reservationPrice: IMoneyInput | null;
    currentStep: ReservationFormSteps;
    clientSecret: string | null;
    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;
    isReservationPriceLoading: boolean;
    shouldReestimateReservationPrice: boolean;
    isNavigationOutsideView: boolean;
}

export interface ISetReservationDetails {
    reservationDetails: IReservationDetails;
}
export interface ISetPurchaserDetails {
    purchaserDetails: IPurchaserDetails;
}

export interface ISetReservationSliceLoading {
    isLoading: boolean;
}

export interface ISetReservationPriceLoading {
    isReservationPriceLoading: boolean;
}

export interface ISetReservationSliceError {
    error: string | null;
}

export interface IReservationForm {
    reservationFormInput: IReservationCreateInput;
}

export interface ISetReservationPrice {
    reservationPrice: IMoneyInput | null;
}

const reservationDetailsInitialState: IReservationDetails = {
    date: null,
    count: null,
    time: null,
    roomId: null,
};

const purchaserDetailsInitialState: IPurchaserDetails = {
    guestName: null,
    phone: null,
    email: null,
    message: null,
    privacyPolicy: null,
};

const initialState: IReservationState = {
    reservationDetails: reservationDetailsInitialState,
    purchaserDetails: purchaserDetailsInitialState,
    reservationPrice: null,
    currentStep: ReservationFormSteps.RESERVATION_DETAILS,
    clientSecret: null,
    isInitialized: false,
    isLoading: false,
    isReservationPriceLoading: false,
    error: null,
    shouldReestimateReservationPrice: false,
    isNavigationOutsideView: false,
};

const reservationSlice = createBaseReducerSlice({
    name: 'reservationSlice',
    initialState: initialState,
    reducers: {
        setReservationDetails: {
            reducer: (state: IReservationState, action: PayloadAction<ISetReservationDetails>) => {
                {
                    return {
                        ...state,
                        reservationDetails: action.payload.reservationDetails,
                        isLoading: false,
                    };
                }
            },
            prepare(reservationDetails: IReservationDetails) {
                return {
                    payload: {reservationDetails},
                };
            },
        },
        setPurchaserDetails: {
            reducer: (state: IReservationState, action: PayloadAction<ISetPurchaserDetails>) => {
                {
                    return {
                        ...state,
                        purchaserDetails: action.payload.purchaserDetails,
                        isLoading: false,
                    };
                }
            },
            prepare(purchaserDetails: IPurchaserDetails) {
                return {
                    payload: {purchaserDetails},
                };
            },
        },
        setReservationPrice: {
            reducer: (state: IReservationState, action: PayloadAction<ISetReservationPrice>) => {
                {
                    return {
                        ...state,
                        reservationPrice: action.payload.reservationPrice,
                        isLoading: false,
                    };
                }
            },
            prepare(reservationPrice: IMoneyInput | null) {
                return {
                    payload: {reservationPrice},
                };
            },
        },

        setReservationSliceError: {
            reducer: (state: IReservationState, action: PayloadAction<ISetReservationSliceError>) => {
                {
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                }
            },
            prepare(error: string | null) {
                return {
                    payload: {error},
                };
            },
        },
        setReservationSliceLoading: {
            reducer: (state: IReservationState, action: PayloadAction<ISetReservationSliceLoading>) => {
                {
                    return {
                        ...state,
                        isLoading: action.payload.isLoading,
                    };
                }
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading},
                };
            },
        },
        setReservationPriceLoading: {
            reducer: (state: IReservationState, action: PayloadAction<ISetReservationPriceLoading>) => {
                {
                    return {
                        ...state,
                        isReservationPriceLoading: action.payload.isReservationPriceLoading,
                    };
                }
            },
            prepare(isReservationPriceLoading: boolean) {
                return {
                    payload: {isReservationPriceLoading},
                };
            },
        },
        setClientSecret: (state: IReservationState, action: PayloadAction<string | null>) => {
            state.clientSecret = action.payload;
        },
        getClientSecret: {
            reducer: (state: IReservationState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(clientSecret: string) {
                return {
                    payload: {clientSecret: clientSecret},
                };
            },
        },

        setIsOutsideNavigation: {
            reducer: (state: IReservationState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    isNavigationOutsideView: action.payload,
                };
            },
            prepare(isNavigationOutsideView: boolean) {
                return {
                    payload: isNavigationOutsideView,
                };
            },
        },
        setShouldReestimateReservationPrice: {
            reducer: (state: IReservationState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    shouldRecalculateJobDetails: action.payload,
                };
            },
            prepare(shouldReestimateReservationPrice: boolean) {
                return {
                    payload: shouldReestimateReservationPrice,
                };
            },
        },

        reservationForm: {
            reducer: (state: IReservationState) => {
                return {
                    ...state,
                    error: null,
                    isLoading: true,
                };
            },
            prepare(reservationForm: IReservationCreateInput) {
                return {
                    payload: {reservationForm},
                };
            },
        },

        setCurrentStep: {
            reducer: (state: IReservationState, action: PayloadAction<ReservationFormSteps>) => {
                return {
                    ...state,
                    currentStep: action.payload,
                };
            },
            prepare(currentStep: ReservationFormSteps) {
                return {
                    payload: currentStep,
                };
            },
        },
        resetReservation: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setReservationDetails,
    setPurchaserDetails,
    setReservationPrice,
    setClientSecret,
    getClientSecret,
    setReservationSliceError,
    setReservationSliceLoading,
    setReservationPriceLoading,
    setIsOutsideNavigation,
    setShouldReestimateReservationPrice,
    reservationForm,
    setCurrentStep,
    resetReservation,
} = reservationSlice.actions;

export default reservationSlice.reducer;
