import {PayloadAction} from '@reduxjs/toolkit';
import {createBaseReducerSlice, IRestaurantOutput} from 'reservation-common-web';

export interface ISetRestaurantLoading {
    isLoading: boolean;
}

export interface ISetRestaurantError {
    error: string | null;
}

export interface ISetRestaurant {
    restaurant: IRestaurantOutput;
}

export interface IRestaurantState {
    restaurant: IRestaurantOutput | null;
    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;
}

const initialState: IRestaurantState = {
    restaurant: null,
    isLoading: true,
    isInitialized: false,
    error: null,
};

const restaurantSlice = createBaseReducerSlice({
    name: 'restaurantSlice',
    initialState: initialState,
    reducers: {
        getRestaurant: (state: IRestaurantState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        setRestaurant: {
            reducer: (state: IRestaurantState, action: PayloadAction<ISetRestaurant>) => {
                {
                    return {
                        ...state,
                        restaurant: action.payload.restaurant,
                        isLoading: false,
                    };
                }
            },
            prepare(restaurant: IRestaurantOutput) {
                return {
                    payload: {restaurant},
                };
            },
        },

        setRestaurantError: {
            reducer: (state: IRestaurantState, action: PayloadAction<ISetRestaurantError>) => {
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        isLoading: false,
                    };
                }
            },
            prepare(error: string | null) {
                return {
                    payload: {error},
                };
            },
        },
    },
});

export const {getRestaurant, setRestaurant, setRestaurantError} = restaurantSlice.actions;

export default restaurantSlice.reducer;
