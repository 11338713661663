import React from 'react';
import {Translation, IRestaurantOutput} from 'reservation-common-web';
import {useDispatch, useSelector} from 'react-redux';
import {ReservationFormSteps, setCurrentStep} from '../../../../store/reducers/reservationSlice';
import {restaurantColorSelector, restaurantSelector} from '../../../../store/selectors/restaurantSelector';
import {btnPrimaryOutlineStyles} from '../../../../utils/stylesUtil';

const RestaurantPlanStep: React.FC = () => {
    const dispatch = useDispatch();
    const restaurant: IRestaurantOutput | null = useSelector(restaurantSelector),
        restaurantColor: string | null = useSelector(restaurantColorSelector);
    const planSrc = 'https://i.pinimg.com/736x/34/12/4b/34124bcef7cd83aab5dbfe79bc591be6.jpg';

    console.log(restaurant);

    return (
        <div className="step restaurant-plan-step">
            <div className="title-container">
                <h2 className="title">
                    <Translation text={'reservationForm.restaurantPlan.title'} />
                </h2>
            </div>
            <div className="restaurant-plan-container">
                <div className="restaurant-plan" style={{backgroundImage: `url(${planSrc})`}} />
            </div>
            <div className="form-footer">
                <button
                    className="btn-primary-outline"
                    style={btnPrimaryOutlineStyles(restaurantColor)}
                    onClick={() => dispatch(setCurrentStep(ReservationFormSteps.RESERVATION_DETAILS))}>
                    <span className="btn-icon" />
                    <Translation text={'reservationForm.buttons.back'} />
                </button>
            </div>
        </div>
    );
};

export default RestaurantPlanStep;
