import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IRestaurantState} from '../reducers/restaurantSlice';

export const selectRestaurantSlice = (state: RootState) => state.restaurant;

export const restaurantSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => state.restaurant);

export const restaurantColorSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.restaurant?.configuration.color || null
);

export const restaurantIsLoadingSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => state.isLoading);
