import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import {useSelector} from 'react-redux';
import {clientSecretSelector} from '../../../../store/selectors/reservationSelector';

const STRIPE_PUBLIC_KEY = 'pk_test_51QQ4kyLFBsEGpEtCKCUs5IvJBF0pg3KkMUWAFtg2iNi73zOjEyXrQnogXSOrOzy6bprbgpETrBnv8BbULhlDKlgQ00E0c6EFBh';
const loader = 'auto';
const appearance = {};

const StripePaymentStep: React.FC = () => {
    const clientSecret: string | null = useSelector(clientSecretSelector);

    const stripe = loadStripe(STRIPE_PUBLIC_KEY);

    const renderElement = () => {
        if (!clientSecret) return <div />;
        return (
            <Elements stripe={stripe} options={{clientSecret, appearance, loader}}>
                <CheckoutForm />
            </Elements>
        );
    };

    return renderElement();
};

export default StripePaymentStep;

// import React, {useEffect, useState} from 'react';
// import {Elements} from '@stripe/react-stripe-js';
// import CheckoutForm from './CheckoutForm';
// import {loadStripe, Stripe} from '@stripe/stripe-js';
// interface IStripePaymentStepProps {}
//
// const STRIPE_PUBLIC_KEY = 'pk_test_51QQ4kyLFBsEGpEtCKCUs5IvJBF0pg3KkMUWAFtg2iNi73zOjEyXrQnogXSOrOzy6bprbgpETrBnv8BbULhlDKlgQ00E0c6EFBh';
//
// // type IMode = 'payment' | 'setup' | 'subscription' | undefined;
//
// const StripePaymentStep: React.FC<IStripePaymentStepProps> = () => {
//     const [clientSecret, setClientSecret] = useState<string | null>(null);
//     const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
//
//     useEffect(() => {
//         fetch('/api/config').then(async () => {
//             setStripePromise(loadStripe(STRIPE_PUBLIC_KEY));
//         });
//     }, []);
//
//     useEffect(() => {
//         // Create PaymentIntent as soon as the page loads
//         fetch('/api/create-payment-intent')
//             .then((res) => res.json())
//             .then(({clientSecret}) => setClientSecret(clientSecret));
//     }, []);
//
//     // const stripe = Stripe('pk_test_51AROWSJX9HHJ5bycpEUP9dK39tXufyuWogSUdeweyZEXy3LC7M8yc5d9NlQ96fRCVL0BlAu7Nqt4V7N5xZjJnrkp005fDiTMIr');
//
//     const renderStripeElement = () => {
//         if (!clientSecret || !stripePromise) return;
//
//         // const mode: IMode = 'payment';
//
//         // const options = {
//         //     mode: mode,
//         //     amount: 100,
//         //     currency: 'pl',
//         //     // Fully customizable with appearance API.
//         //     appearance: {
//         //         theme: 'flat',
//         //         variables: {colorPrimaryText: '#262626'},
//         //     },
//         //     clientSecret: clientSecret,
//         // };
//
//         return (
//             <Elements stripe={stripePromise} options={{clientSecret}}>
//                 <CheckoutForm />
//             </Elements>
//         );
//     };
//
//     return <div className="step payment-step">{renderStripeElement()}</div>;
// };
//
// export default StripePaymentStep;
