import React, {useEffect} from 'react';
import {Loader, LoaderType, Toast, IRestaurantOutput} from 'reservation-common-web';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {restaurantIsLoadingSelector, restaurantSelector} from '../../store/selectors/restaurantSelector';
import {getRestaurant} from '../../store/reducers/restaurantSlice';
import ReservationCard from '../ReservationCard';

const DEFAULT_BG = 'https://www.malibumakos.com/wp-content/uploads/2018/07/ocean-waves-1000x675-1000x675.jpg';

const ViewHost: React.FC = () => {
    const dispatch = useDispatch(),
        {slug} = useParams(),
        restaurant: IRestaurantOutput | null = useSelector(restaurantSelector),
        isLoading = useSelector(restaurantIsLoadingSelector);

    useEffect(() => {
        if (slug) dispatch(getRestaurant({slug: slug}));
    }, [slug]);

    const bgUrl = restaurant?.configuration?.background?.fileUrls?.original,
        bgSrc = bgUrl ? bgUrl : DEFAULT_BG;

    const renderRestaurantFormView = () => {
        if (isLoading) return;

        if (!restaurant) return;

        return (
            <div className="reservation-card-view" style={{backgroundImage: `url(${bgSrc})`}}>
                <ReservationCard />
            </div>
        );
    };

    return (
        <div className="view">
            {renderRestaurantFormView()}
            <Toast />
            <Loader showLoader={isLoading} type={LoaderType.Global} />
        </div>
    );
};

export default ViewHost;
