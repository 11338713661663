import React from 'react';
import {Translation} from 'reservation-common-web';
import {useDispatch, useSelector} from 'react-redux';
import {ReservationFormSteps, resetReservation, setCurrentStep} from '../../../../store/reducers/reservationSlice';
import {restaurantColorSelector} from '../../../../store/selectors/restaurantSelector';
import {btnPrimaryOutlineStyles, btnPrimaryStyles} from '../../../../utils/stylesUtil';

const ThankYouStep: React.FC = () => {
    const dispatch = useDispatch();
    const restaurantColor: string | null = useSelector(restaurantColorSelector);

    return (
        <div className="step privacy-policy-step">
            <div className="title-container">
                <h2 className="title">
                    <Translation text={'reservationForm.thankYou.title'} />
                </h2>
            </div>
            <div>{'Wysłaliśmy  do Ciebie maila ze szczegółami. Do zobaczenia na miejscu.'}</div>

            <div className="form-footer">
                <button
                    className="btn-primary"
                    style={btnPrimaryStyles(restaurantColor)}
                    onClick={() => {
                        dispatch(resetReservation());
                        dispatch(setCurrentStep(ReservationFormSteps.RESERVATION_DETAILS));
                    }}>
                    <Translation text={'reservationForm.buttons.restart'} />
                </button>
                <button
                    className="btn-primary-outline"
                    style={btnPrimaryOutlineStyles(restaurantColor)}
                    onClick={() => {
                        dispatch(setCurrentStep(ReservationFormSteps.PURCHASER_DETAILS));
                    }}>
                    <Translation text={'reservationForm.buttons.back'} />
                </button>
            </div>
        </div>
    );
};

export default ThankYouStep;
